import {FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React, {useEffect} from 'react';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {FormOverrides} from '@wix/form-viewer';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useExperiments, usePanorama} from '@wix/yoshi-flow-editor';
import {getCustomerDetailsFormOverrides} from './CustomerDetailsForm.utils';
import {CustomerDetailsFormSection, FormFieldTarget} from '../../../../types/checkoutApp.types';
import {classes} from './CustomerDetailsForm.st.css';
import {FormProps} from '../form.utils';
import {SPECS} from '../../../../common/constants';

export interface CustomerDetailsFormProps extends FormProps {
  formSections?: CustomerDetailsFormSection[];
  dataHook?: string;
}

const CustomerDetailsFormInternal = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  formSections,
  dataHook,
}: CustomerDetailsFormProps) => {
  const i18n = useICUI18n();
  const panorama = usePanorama();
  const {
    checkoutSettingsStore: {checkoutSettings},
    checkoutStore: {isShippingFlow, isFastFlow, setIsSubscriptionCheckboxCheckedByDefault},
    memberStore: {isMember, currentUserEmail},
    formsStore: {doesFormHaveSubscription, isSubscriptionCheckboxCheckedByDefault},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const shouldHideEmailOnForm =
    experiments.enabled(SPECS.TestNewSubscriptionCheckboxLocation) && checkoutSettings.isSubscriptionEnabled;
  const shouldCheckSubscriptionCheckboxInitialValue =
    experiments.enabled(SPECS.UseNewSubscriptionCheckboxInCustomerDetailsForm) &&
    doesFormHaveSubscription &&
    isSubscriptionCheckboxCheckedByDefault === null;

  useEffect(() => {
    if (isMember) {
      formValues.email = currentUserEmail;
    }
  });

  return (
    <div className={classes.root} data-hook={dataHook}>
      <FormViewer
        i18n={i18n}
        panorama={panorama}
        ref={formRef}
        formId={
          experiments.enabled(SPECS.MigrateSubscriptionToCheckoutForm)
            ? /* istanbul ignore next */ FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_SUBSCRIPTION
            : FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO
        }
        values={formValues}
        errors={formErrors}
        onChange={(value) => {
          /* istanbul ignore next */
          shouldCheckSubscriptionCheckboxInitialValue &&
            setIsSubscriptionCheckboxCheckedByDefault(Boolean(value[FormFieldTarget.subscribe]));
          setFormValues(value);
        }}
        overrides={
          getCustomerDetailsFormOverrides({
            checkoutSettings,
            excludeGoogleAutoComplete: experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
            isShippingFlow,
            isMember,
            formSections,
            isFastFlow,
            shouldTestNewSubscriptionCheckboxLocation: shouldHideEmailOnForm,
          }) as FormOverrides
        }
        onValidate={setFormErrors}
      />
    </div>
  );
};

export const CustomerDetailsForm = React.memo(CustomerDetailsFormInternal);
