import React, {ReactNode} from 'react';
import {RadioButton, RadioButtonTheme, Badge, BadgePriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {DeliveryMethodPickerDataHook} from './DeliveryMethodPicker';
import {classes} from './DeliveryMethodPicker.st.css';
import {ShippingOptionModel} from '../../../../domain/models/checkout/ShippingOption.model';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../common/constants';

export interface DeliveryMethodProps {
  option: ShippingOptionModel;
  onChange?: () => void;
  selectedId?: string;
  subtext?: string;
  disabled?: boolean;
  selectedDetailSection?: () => ReactNode;
}

export const DeliveryMethod = ({
  option,
  onChange,
  selectedId,
  subtext,
  selectedDetailSection,
  disabled,
}: DeliveryMethodProps) => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const isChecked = selectedId === option.code;
  const localeKeys = useLocaleKeys();
  const isFree = option.price?.amount === 0;
  const shouldShowBadge =
    !option.partial && checkout.hasPartialShippingOptions && experiments.enabled(SPECS.SupportDeliveryProfilesPhase2);

  return (
    <>
      <RadioButton
        data-hook={DeliveryMethodPickerDataHook.shippingOption}
        className={classes.radioButton}
        value={option.code}
        checked={isChecked}
        label={option.title}
        subtext={subtext}
        disabled={disabled}
        suffix={
          <div className={classes.radioButtonSuffix}>
            {shouldShowBadge && (
              <Badge
                data-hook={DeliveryMethodPickerDataHook.shippingOptionBadge}
                className={classes.radioButtonBadge}
                priority={BadgePriority.secondary}>
                {localeKeys.checkout.deliveryMethod.deliveryProfiles.availableForAllItems.badge()}
              </Badge>
            )}
            <span data-hook={DeliveryMethodPickerDataHook.shippingOptionPrice} className={classes.priceTag}>
              {isFree ? localeKeys.checkout.delivery_method.free_label() : option.price.formattedAmount}
            </span>
          </div>
        }
        onChange={onChange}
        theme={RadioButtonTheme.Box}
      />
      {isChecked && selectedDetailSection?.()}
    </>
  );
};
