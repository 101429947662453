import React, {useRef, useState} from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './CustomerDetailsOpen.st.css';
import {CustomerDetailsStepDataHook} from '../CustomerDetailsStep';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';
import {useCustomerDetailsData} from '../WithCustomerDetailsData/WithCustomerDetailsData';
import {CustomerDetailsForm} from '../CustomerDetailsForm/CustomerDetailsForm';
import {MemberDetails} from '../../../../MemberDetails/MemberDetails';
import {DetailsFormButtons} from '../../../StepsManager/Components/DetailsFormButtons/DetailsFormButtons';
import {useContinueButtonState} from '../../../StepsManager/Components/DetailsFormButtons/UseContinueButtonState';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {SPECS} from '../../../../../../common/constants';
import {validateEmailAddress} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm.utils';

export enum CustomerDetailsDataHook {
  customerDetailsForm = 'CustomerDetails.customerDetailsForm',
  disabledMemberEmail = 'CustomerDetails.disabledMemberEmail',
  temporaryEmailForSubscriptionABTest = 'CustomerDetails.temporaryEmailForSubscriptionABTest',
  subscriptionCheckbox = 'CustomerDetails.subscriptionCheckbox',
  subscriptionLabel = 'CustomerDetails.subscriptionLabel',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CustomerDetailsOpen = () => {
  const sectionRootRef = useRef<HTMLDivElement>(null);
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {submitCustomerDetails, checkout, isShippingFlow, onInvalidDetailsFormSubmit},
    memberStore: {isMember, isAddressesAppInstalled},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const {isFormValid, getCustomerDetailsForSubmit} = useCustomerDetailsData();
  const {selectedAddressesServiceId, isAddNewChecked, isSetAsDefaultChecked} = useMemberDetailsData();
  const [emailValue, setEmailValue] = useState(checkout.buyerInfo.email ?? '');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const {continueButtonState, isContinueButtonDisabled, setContinueButtonState, setIsContinueButtonDisabled} =
    useContinueButtonState();

  const validateAndSubmit = async () => {
    setIsContinueButtonDisabled(true);
    setContinueButtonState(StatesButtonStates.IN_PROGRESS);

    const shouldTestNewSubscriptionCheckboxLocation =
      experiments.enabled(SPECS.TestNewSubscriptionCheckboxLocation) &&
      !isMember &&
      checkoutSettings.isSubscriptionEnabled;

    const isTempEmailValid = shouldTestNewSubscriptionCheckboxLocation ? validateEmailAddress(emailValue) : true;
    setIsEmailValid(isTempEmailValid);
    const isValid = (await isFormValid()) && isTempEmailValid;

    if (isValid) {
      const addressesServiceId = isAddNewChecked ? ADD_NEW_ADDRESS_ID : selectedAddressesServiceId;
      void submitCustomerDetails({
        ...getCustomerDetailsForSubmit(),
        addressesServiceId: isShippingFlow ? addressesServiceId : undefined,
        setAsDefault: isSetAsDefaultChecked,
        ...(shouldTestNewSubscriptionCheckboxLocation ? {email: emailValue} : {}),
      });
    } else {
      onInvalidDetailsFormSubmit();
      setIsContinueButtonDisabled(false);
      setContinueButtonState(StatesButtonStates.IDLE);
      if (!isEmailValid) {
        scrollToSectionRoot();
      }
    }
  };

  const scrollToSectionRoot = () => {
    sectionRootRef?.current?.scrollIntoView({block: 'start'});
  };

  return (
    <div data-hook={CustomerDetailsStepDataHook.open} className={classes.root} ref={sectionRootRef}>
      {isMember && isAddressesAppInstalled && isShippingFlow ? (
        <MemberDetails
          Form={() => <CustomerDetailsForm />}
          dropdownLabel={localeKeys.chooseAddressModal.shipping_address_title()}
          customField={checkout.customField}
        />
      ) : (
        <CustomerDetailsForm
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          isEmailValid={isEmailValid}
          setIsEmailValid={setIsEmailValid}
        />
      )}
      <DetailsFormButtons
        onCancel={scrollToSectionRoot}
        onContinue={() => void validateAndSubmit()}
        continueButtonDisabled={isContinueButtonDisabled}
        continueButtonState={continueButtonState}
        shouldIncludeCancelButton={isShippingFlow}
      />
    </div>
  );
};
