import React, {useEffect, useState} from 'react';
import {classes} from './CodeInputSection.st.css';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {CodeInput} from './CodeInput/CodeInput';
import {Tooltip} from 'wix-ui-tpa';

export interface CodeInputSectionProps {
  dataHook: string;
  enterACodeButtonText: string;
  ariaLabel?: string;
  onCodeInputOpen?: () => void;
  applyCodeText: string;
  removeCodeText: string;
  codeValue?: string;
  onApplyCode: (code: string) => void;
  onRemoveCode: () => void;
  error?: string;
  icon?: React.ReactElement;
  placeholder?: string;
  isLoading?: boolean;
  isEnterACodeButtonDisabled: boolean;
  enterACodeButtonDisabledTooltipData: {dataHook: string; content: string};
}

export enum CodeInputSectionDataHooks {
  EnterACodeButton = 'CodeInputSection.EnterACodeButton',
  InputSection = 'CodeInputSection.InputSection',
}

export const CodeInputSection = ({
  dataHook,
  enterACodeButtonText,
  onCodeInputOpen,
  applyCodeText,
  removeCodeText,
  codeValue,
  onApplyCode,
  onRemoveCode,
  error,
  icon,
  placeholder,
  isLoading,
  isEnterACodeButtonDisabled,
  enterACodeButtonDisabledTooltipData,
  ariaLabel,
}: CodeInputSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(!codeValue);

  useEffect(
    () => {
      if (!codeValue) {
        return;
      }

      setIsCollapsed(!codeValue);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [codeValue]
  );

  const textButton = (
    <TextButton
      className={classes.textButton}
      data-hook={CodeInputSectionDataHooks.EnterACodeButton}
      priority={TextButtonPriority.primary}
      prefixIcon={icon}
      aria-expanded={!isCollapsed}
      onClick={() => {
        isCollapsed && onCodeInputOpen?.();
        setIsCollapsed(!isCollapsed);
      }}
      disabled={isEnterACodeButtonDisabled}>
      {enterACodeButtonText}
    </TextButton>
  );

  return (
    <div data-hook={dataHook}>
      <div className={classes.enterACodeButton}>
        {isEnterACodeButtonDisabled ? (
          <Tooltip
            content={enterACodeButtonDisabledTooltipData.content}
            data-hook={enterACodeButtonDisabledTooltipData.dataHook}>
            {textButton}
          </Tooltip>
        ) : (
          textButton
        )}
      </div>
      {!isCollapsed && (
        <div className={classes.codeInput}>
          <CodeInput
            dataHook={CodeInputSectionDataHooks.InputSection}
            applyText={applyCodeText}
            removeText={removeCodeText}
            appliedValue={codeValue}
            onApply={onApplyCode}
            onRemove={onRemoveCode}
            error={error}
            placeholder={placeholder}
            isLoading={isLoading}
            ariaLabel={ariaLabel}
          />
        </div>
      )}
    </div>
  );
};
