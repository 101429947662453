import React from 'react';
import {classes} from './ViolationsAndPlaceOrderButton.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../types/checkoutApp.types';
import {PlaceOrderButton} from '../PlaceOrderButton/PlaceOrderButton';
import {Violations} from '../Violations/Violations';
import {getViolationsAndPlaceOrderButtonDataHook} from './ViolationsAndPlaceOrderButton.utils';

export interface ViolationsAndPlaceOrderButtonProps {
  location: ViolationsAndPlaceOrderButtonLocation;
  checkboxesRef?: React.RefObject<HTMLDivElement>;
  paymentWidgetRef?: React.RefObject<HTMLDivElement>;
}

export const ViolationsAndPlaceOrderButton = ({
  location,
  checkboxesRef,
  paymentWidgetRef,
}: ViolationsAndPlaceOrderButtonProps) => {
  const {
    checkoutStore: {shouldShowViolations, mainViolationsToDisplay},
    stepsManagerStore: {shouldShowMemberLoginStep},
  } = useControllerProps();
  const dataHook = getViolationsAndPlaceOrderButtonDataHook(location);
  const className = location === ViolationsAndPlaceOrderButtonLocation.summary ? classes.rootMobile : classes.root;

  return shouldShowMemberLoginStep ? null : (
    <div className={className}>
      {shouldShowViolations && <Violations dataHook={dataHook} violations={mainViolationsToDisplay} />}
      <div className={classes.placeOrderButtonMobile}>
        <PlaceOrderButton checkboxesRef={checkboxesRef} paymentWidgetRef={paymentWidgetRef} />
      </div>
    </div>
  );
};
