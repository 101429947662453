import React from 'react';
import {Text, Button, ButtonPriority} from 'wix-ui-tpa/cssVars';
import {MemberLoginStepDataHook} from '../MemberLoginStep';
import {classes} from './MemberLoginOpen.st.css';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';

export enum MemberLoginDataHook {
  subtitle = 'MemberLoginDataHook.subtitle',
  loginButton = 'MemberLoginDataHook.loginButton',
  signupButton = 'MemberLoginDataHook.signupButton',
}

export const MemberLoginOpen = () => {
  const {
    navigationStore: {onLogin, onSignup},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  return (
    <div data-hook={MemberLoginStepDataHook.open}>
      <Text data-hook={MemberLoginDataHook.subtitle} className={classes.subtitle}>
        {localeKeys.checkout.membersSignIn.subtitle()}
      </Text>
      <div className={classes.buttonsWrapper}>
        <Button
          data-hook={MemberLoginDataHook.loginButton}
          priority={ButtonPriority.basic}
          onClick={() => void onLogin()}
          wrapContent
          upgrade>
          {localeKeys.checkout.membersSignIn.logIn.cta()}
        </Button>
        <Button
          data-hook={MemberLoginDataHook.signupButton}
          priority={ButtonPriority.basicSecondary}
          onClick={() => void onSignup()}
          wrapContent
          upgrade>
          {localeKeys.checkout.membersSignIn.signUp.cta()}
        </Button>
      </div>
    </div>
  );
};
