import React from 'react';
import {Card, Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes as pickupInfoBoxClasses} from '../PickupInfoBox/PickupInfoBox.st.css';
import {classes, st} from './ShippingInfoBox.st.css';

export enum ShippingInfoBoxDataHook {
  root = 'ShippingInfoBox.root',
  instructionsTitle = 'ShippingInfoBox.instructionsTitle',
  instructionsDetails = 'ShippingInfoBox.instructionsDetails',
}

export interface ShippingInfoBoxProps {
  instructions?: string;
  insideTimeSelector?: boolean;
}

const ShippingInfoBoxInternal = ({instructions, insideTimeSelector}: ShippingInfoBoxProps) => {
  const localeKeys = useLocaleKeys();

  return instructions ? (
    <div className={insideTimeSelector ? pickupInfoBoxClasses.insideTimeSelector : pickupInfoBoxClasses.root}>
      <Card data-hook={ShippingInfoBoxDataHook.root} className={st(classes.fontSize, pickupInfoBoxClasses.card)}>
        <Card.Container>
          <Text
            tagName={'h4'}
            data-hook={ShippingInfoBoxDataHook.instructionsTitle}
            priority={TextPriority.primary}
            className={st(pickupInfoBoxClasses.title)}>
            {localeKeys.checkout.deliveryMethod.deliveryInstructions.title()}
          </Text>
          <Text
            data-hook={ShippingInfoBoxDataHook.instructionsDetails}
            priority={TextPriority.secondary}
            className={st(pickupInfoBoxClasses.instructions)}>
            {instructions}
          </Text>
        </Card.Container>
      </Card>
    </div>
  ) : null;
};

export const ShippingInfoBox = React.memo(ShippingInfoBoxInternal);
