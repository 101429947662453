import React, {FC} from 'react';
import {useTranslation, WidgetProps} from '@wix/yoshi-flow-editor';
import {ControllerContext} from './ControllerContext';
import {CheckoutControllerProps} from '../../../types/checkoutApp.types';
import {LocaleKeysProvider} from '../../../locale-keys/LocaleKeys';
import CheckoutApp from '../CheckoutApp/CheckoutApp';
import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi';
import {FormViewerContext} from '@wix/form-viewer/widget';
import {withExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {withPaymentsApi} from '../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {withFunctionObservationResult} from '@wix/function-result-observation';
import './index.css';
import {withConditionalDeliverySection} from '../../ConditionalDeliverySection';

const Widget: FC<WidgetProps<CheckoutControllerProps>> = (controllerProps: WidgetProps<CheckoutControllerProps>) => {
  const {t} = useTranslation();

  return (
    <ControllerContext value={controllerProps}>
      <LocaleKeysProvider
        translateFn={(key: any, options: any, ...rest: unknown[]) =>
          t(key, {...options, interpolation: {escapeValue: false}, ...rest})
        }>
        <FormViewerContext {...controllerProps}>
          <CheckoutApp host={controllerProps.host} ravenUserContextOverrides={{}} />
        </FormViewerContext>
      </LocaleKeysProvider>
    </ControllerContext>
  );
};

export default withFunctionObservationResult(
  withPaymentsApi(withExpressCheckoutButtons(withSlotsPlaceholders(withConditionalDeliverySection(Widget))))
);
