import React from 'react';
import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import {Step} from '../../StepsManager/Components/Step';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps, StepState} from '../../../../../types/checkoutApp.types';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {withCustomerDetailsData} from './WithCustomerDetailsData/WithCustomerDetailsData';
import {withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {classes as commonClasses} from '../../../../../common/common.st.css';
import {CustomerDetailsEmpty} from './Components/CustomerDetailsEmpty';

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const CustomerDetailsStep = ({index}: StepImplementationProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {isShippingFlow},
    memberStore: {isMember},
    stepsManagerStore: {stepsList},
    navigationStore: {isDonationsTheme},
    checkoutSettingsStore: {checkoutContent},
  } = useControllerProps();
  const stepState = stepsList[0].state;

  const getTitle = (): string => {
    let title = localeKeys.checkout.customer_details();

    if (isDonationsTheme) {
      title = localeKeys.checkout.donations.donor_details();
    } else if (!isMember && stepState === StepState.COLLAPSED && isShippingFlow) {
      title = localeKeys.checkout.customer_delivery_details_title();
    } else if (isMember && isShippingFlow) {
      title = localeKeys.checkout.delivery_details_title();
    }

    return title;
  };

  const titleText = getTitle();
  const titleTextWithOverride = checkoutContent?.customerDetails ?? titleText;

  return (
    <Step
      index={index!}
      title={titleTextWithOverride}
      dataHook={CustomerDetailsStepDataHook.root}
      openComponent={WrappedCustomerDetailsOpenStep}
      collapsedComponent={CustomerDetailsCollapsed}
      emptyComponent={CustomerDetailsEmpty}
      classNameDynamicStep={commonClasses.oneColumnViewPadding}
    />
  );
};

const WrappedCustomerDetailsOpenStep = withMemberDetailsData(
  withCustomerDetailsData(CustomerDetailsOpen),
  (checkout) => checkout.shippingDestination,
  'validateShippingAddress'
);
