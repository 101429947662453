import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../Widget/ControllerContext';
import {MemberLoginOpen} from '../../CheckoutSteps/Steps/MemberLoginStep/Components/MemberLoginOpen';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes as commonClasses} from '../../../../common/common.st.css';
import {classes} from './MemberOnlyFastFlow.st.css';

export enum MemberOnlyFastFlowDataHook {
  root = 'MemberOnlyFastFlowDataHook.root',
  title = 'MemberOnlyFastFlowDataHook.title',
  disabledPlaceOrderTitle = 'MemberOnlyFastFlowDataHook.disabledPlaceOrderTitle',
}

export const MemberOnlyFastFlow = () => {
  const {
    stepsManagerStore: {shouldShowMemberLoginStep},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  return (
    <div data-hook={MemberOnlyFastFlowDataHook.root} className={classes.root}>
      <Text tagName={'h2'} className={classes.title} data-hook={MemberOnlyFastFlowDataHook.title}>
        {localeKeys.checkout.membersSignIn.title()}
      </Text>
      <div className={commonClasses.oneColumnViewPadding}>{shouldShowMemberLoginStep && <MemberLoginOpen />}</div>
      <Text
        tagName={'h2'}
        className={classes.disabledPlaceOrderTitle}
        data-hook={MemberOnlyFastFlowDataHook.disabledPlaceOrderTitle}>
        {localeKeys.checkout.place_order_fast_flow.title()}
      </Text>
    </div>
  );
};
