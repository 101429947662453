import React, {useEffect, useRef, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './DeliveryMethodOpen.st.css';
import {DeliveryMethodStepDataHook} from '../DeliveryMethodStep';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {DeliveryMethodSection} from '../../../../DeliveryMethodSection/DeliveryMethodSection';
import {AddShippingAddressSection} from './AddShippingAddressSection/AddShippingAddressSection';
import {Violations} from '../../../../Violations/Violations';
import {hasErrorViolations} from '../../../../../../domain/utils/violations.utils';

export const DeliveryMethodOpen = () => {
  const localeKeys = useLocaleKeys();
  const {
    deliveryMethodStore,
    checkoutStore: {onDeliveryMethodContinue, shouldShowViolations, deliveryViolationsToDisplay},
  } = useControllerProps();
  const {isUpdatingShippingOption, canShipToDestination} = deliveryMethodStore;
  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const hasErrorViolation = hasErrorViolations(deliveryViolationsToDisplay);
  const [buttonDisabledState, setButtonDisabledState] = useState(!canShipToDestination || hasErrorViolation);
  const sectionRootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setButtonDisabledState(isUpdatingShippingOption || !canShipToDestination || hasErrorViolation);
  }, [isUpdatingShippingOption, canShipToDestination, hasErrorViolation]);

  const submit = () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);
    onDeliveryMethodContinue();
  };

  return (
    <div data-hook={DeliveryMethodStepDataHook.open} className={classes.root} ref={sectionRootRef}>
      <AddShippingAddressSection />
      <DeliveryMethodSection />
      {shouldShowViolations && (
        <Violations dataHook={DeliveryMethodStepDataHook.violations} violations={deliveryViolationsToDisplay} />
      )}
      <div className={classes.nextStepButton}>
        <NextStepButton
          onClick={() => void submit()}
          text={localeKeys.checkout.continue_button_label()}
          dataHook={DeliveryMethodStepDataHook.continueButton}
          disabled={buttonDisabledState}
          buttonState={buttonState}
        />
      </div>
    </div>
  );
};
