import React, {Children, ReactElement} from 'react';

export enum StepsManagerDataHook {
  root = 'StepsManager.root',
}

export interface StepsManagerProps {
  children: (React.ReactElement | null)[];
}

export const StepsManager = ({children}: StepsManagerProps) => {
  const actualChildren = children.filter((child) => !!child);

  return (
    <div data-hook={StepsManagerDataHook.root}>
      {Children.map(actualChildren, (child: ReactElement, index) => React.cloneElement(child, {index}))}
    </div>
  );
};
