import React from 'react';
import {ViolationModel, ViolationSeverity} from '../../../domain/models/checkout/Violation.model';
import {Violation} from './Violation';
import {classes} from './Violations.st.css';

export interface ViolationsProps {
  dataHook: string;
  isTopBanner?: boolean;
  violations?: ViolationModel[];
}

export const Violations = ({isTopBanner, dataHook, violations}: ViolationsProps) => {
  const violationsToDisplay = violations!;

  return violationsToDisplay.length > 0 ? (
    <div data-hook={dataHook} className={classes.root}>
      {violationsToDisplay.map((violation, index) => (
        <Violation
          key={index}
          severity={isTopBanner ? ViolationSeverity.warning : violation.severity}
          description={violation.description}
        />
      ))}
    </div>
  ) : null;
};
