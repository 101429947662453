import {ILocaleKeys} from '../../locale-keys/LocaleKeys';
import {SubscriptionFrequency, SubscriptionModel} from '../models/checkout/Subscription.model';

export const getLineItemSubscriptionFrequencyText = (
  subscription: SubscriptionModel,
  localeKeys: ILocaleKeys
): string => {
  if (subscription.interval > 2) {
    switch (subscription.frequency) {
      case SubscriptionFrequency.DAY: {
        return localeKeys.checkout.page_plan_frequency_description_days_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
      case SubscriptionFrequency.WEEK: {
        return localeKeys.checkout.page_plan_frequency_description_weeks_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
      case SubscriptionFrequency.MONTH: {
        return localeKeys.checkout.page_plan_frequency_description_months_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
      case SubscriptionFrequency.YEAR: {
        return localeKeys.checkout.page_plan_frequency_description_years_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
    }
  }
  switch (subscription.frequency) {
    case SubscriptionFrequency.DAY: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_days_label()
        : localeKeys.checkout.page_plan_frequency_description_day_singular_label();
    }
    case SubscriptionFrequency.WEEK: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_weeks_label()
        : localeKeys.checkout.page_plan_frequency_description_week_singular_label();
    }
    case SubscriptionFrequency.MONTH: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_months_label()
        : localeKeys.checkout.page_plan_frequency_description_month_singular_label();
    }
    case SubscriptionFrequency.YEAR: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_years_label()
        : localeKeys.checkout.page_plan_frequency_description_year_singular_label();
    }
  }
  return '';
};
