import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {StepImplementationProps} from '../../../../../types/checkoutApp.types';
import {classes as commonClasses} from '../../../../../common/common.st.css';
import {MemberLoginOpen} from './Components/MemberLoginOpen';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export enum MemberLoginStepDataHook {
  root = 'MemberLoginStep.root',
  open = 'MemberLoginStep.open',
  empty = 'MemberLoginStep.empty',
}

export const MemberLoginStep = ({index}: StepImplementationProps) => {
  const localeKeys = useLocaleKeys();
  return (
    <Step
      index={index!}
      title={localeKeys.checkout.membersSignIn.title()}
      dataHook={MemberLoginStepDataHook.root}
      openComponent={MemberLoginOpen}
      classNameDynamicStep={commonClasses.oneColumnViewPadding}
    />
  );
};
