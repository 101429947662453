import React, {useEffect} from 'react';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {withSentryErrorBoundary} from '@wix/native-components-infra';
import {ECOM_PLATFORM_CHECKOUT_DSN} from '@wix/wixstores-client-storefront-sdk';
import {Header} from '../Header/Header';
import {Summary} from '../Summary/Summary';
import {PlaceOrderFastFlow} from '../PlaceOrderFastFlow/PlaceOrderFastFlow';
import {Footer} from '../Footer/Footer';
import {useControllerProps} from '../Widget/ControllerContext';
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars';
import {FullFlow} from '../FullFlow/FullFlow';
import {SummaryLocation} from '../../../types/checkoutApp.types';
import {getDirValue} from '../../../domain/utils/getDirValue';
import {Layout} from '../../../common/components/Layout/Layout';
import {pageTheme} from '../../../common/components/WithThemeOverride/pageTheme';

export enum CheckoutAppDataHook {
  root = 'CheckoutAppDataHook.root',
  footer = 'CheckoutAppDataHook.footer',
  header = 'CheckoutAppDataHook.header',
  summary = 'CheckoutAppDataHook.summary',
}

const CheckoutApp = () => {
  const {failedToFetch, isLoading, checkoutStore, isOneColumnView} = useControllerProps();
  const {isRTL} = useEnvironment();

  useEffect(
    () => {
      if (isLoading) {
        return;
      }
      checkoutStore.checkoutPageFinishLoading();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [isLoading]
  );

  return (
    <div style={pageTheme}>
      <TPAComponentsProvider value={{rtl: isRTL}}>
        <Layout
          dataHook={CheckoutAppDataHook.root}
          isLoading={isLoading}
          failedToFetch={failedToFetch}
          isOneColumnView={isOneColumnView}
          dir={getDirValue(isRTL)}
          Header={() => <Header dataHook={CheckoutAppDataHook.header} />}
          Aside={() => <Summary dataHook={CheckoutAppDataHook.summary} location={SummaryLocation.checkoutApp} />}
          Footer={() => <Footer dataHook={CheckoutAppDataHook.footer} />}>
          {checkoutStore?.isFastFlow ? <PlaceOrderFastFlow /> : <FullFlow />}
        </Layout>
      </TPAComponentsProvider>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(CheckoutApp, {
  dsn: ECOM_PLATFORM_CHECKOUT_DSN,
  config: {environment: 'Native Component'},
});
