import React, {useEffect, useState} from 'react';
import {StatesButton, StatesButtonStates, TextField, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';
import {classes} from './CodeInput.st.css';

export enum CodesInputDataHooks {
  TextInput = 'CodesInput.TextInput',
  Button = 'CodesInput.Button',
  ThreeDotsLoader = 'CodesInput.ThreeDotsLoader',
}

export interface CodeInputProps {
  dataHook?: string;
  placeholder?: string;
  appliedValue?: string;
  applyText: string;
  removeText: string;
  onApply: (code: string) => void;
  onRemove: () => void;
  error?: string;
  isLoading?: boolean;
  ariaLabel?: string;
}

export const CodeInput = ({
  dataHook,
  placeholder,
  appliedValue,
  applyText,
  removeText,
  onApply,
  onRemove,
  error,
  isLoading,
  ariaLabel,
}: CodeInputProps) => {
  const [value, setValue] = useState(appliedValue ?? '');
  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setValue(appliedValue ?? '');
  }, [appliedValue]);

  const onButtonClick = () => {
    const isRemove = !!appliedValue;

    if (isRemove) {
      onRemove();
      /* istanbul ignore if */
      if (value !== appliedValue) {
        setValue('');
      }
    } else {
      onApply(value);
    }
  };

  useEffect(
    () => {
      if (isLoading) {
        setButtonState(StatesButtonStates.IN_PROGRESS);
        return;
      }

      inputRef?.focus();
      setButtonState(StatesButtonStates.IDLE);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [isLoading]
  );

  return (
    <div className={classes.container} data-hook={dataHook}>
      <TextField
        data-hook={CodesInputDataHooks.TextInput}
        className={classes.textField}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        successIcon
        success={!!appliedValue && !error && !isLoading}
        disabled={!!appliedValue && !error && !isLoading}
        error={!!error}
        errorMessage={error}
        newErrorMessage
        inputRef={(input: HTMLInputElement) => setInputRef(input)}
        aria-label={ariaLabel}
      />
      <StatesButton
        className={classes.statesButton}
        data-hook={CodesInputDataHooks.Button}
        idleContent={appliedValue ? removeText : applyText}
        state={buttonState}
        disabled={!value}
        onClick={onButtonClick}
        inProgressContent={
          <ThreeDotsLoader data-hook={CodesInputDataHooks.ThreeDotsLoader} className={classes.threeDotButton} />
        }
        upgrade={true}
      />
    </div>
  );
};
