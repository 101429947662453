import React from 'react';
import {classes} from './ItemSubscription.st.css';
import {Text} from 'wix-ui-tpa/cssVars';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../common/constants';
import {SubscriptionFrequency, SubscriptionModel} from '../../../../../domain/models/checkout/Subscription.model';
import {getFrequencyText, getSubscriptionDuration} from '../LineItem.utils';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export interface SubscriptionProps {
  dataHook: string;
  subscription: SubscriptionModel;
}

export enum SubscriptionDataHook {
  Name = 'SubscriptionDataHook.Name',
  Duration = 'SubscriptionDataHook.Duration',
  StartDate = 'SubscriptionDataHook.StartDate',
  FreeTrial = 'SubscriptionDataHook.FreeTrial',
}

export const ItemSubscription = ({subscription, dataHook}: SubscriptionProps) => {
  const {experiments} = useExperiments();
  const {language} = useEnvironment();
  const localeKeys = useLocaleKeys();
  const startDate = subscription.startDate
    ? new Date(Number(subscription.startDate)).toLocaleDateString(language)
    : undefined;
  const shouldUseNewSubscriptionView = experiments.enabled(SPECS.UseNewSubscriptionView);
  const shouldDisplayFreeTrial =
    experiments.enabled(SPECS.SupportFreeTrialLineItems) &&
    subscription.freeTrialInterval &&
    subscription.freeTrialFrequency !== SubscriptionFrequency.UNDEFINED;

  const duration = getSubscriptionDuration(subscription, localeKeys, shouldUseNewSubscriptionView);
  const freeTrialFrequency = shouldDisplayFreeTrial
    ? getFrequencyText({
        frequency: subscription.freeTrialFrequency,
        cycles: subscription.freeTrialInterval!,
        localeKeys,
        shouldUseNewSubscriptionView: true,
      })
    : '';

  return shouldUseNewSubscriptionView ? (
    <div className={classes.root} data-hook={dataHook}>
      {subscription.name && (
        <Text data-hook={SubscriptionDataHook.Name} className={classes.text}>
          {subscription.name}
        </Text>
      )}
      <Text data-hook={SubscriptionDataHook.Duration} className={classes.text}>
        {duration}
      </Text>
      {startDate && (
        <Text data-hook={SubscriptionDataHook.StartDate} className={classes.text}>
          {localeKeys.checkout.pricingPlans.startDate.label({date: startDate})}
        </Text>
      )}
      {shouldDisplayFreeTrial && (
        <Text data-hook={SubscriptionDataHook.FreeTrial} className={classes.text}>
          {localeKeys.checkout.pricingPlans.freeTrial.label({
            number: subscription.freeTrialInterval,
            frequency: freeTrialFrequency,
          })}
        </Text>
      )}
    </div>
  ) : (
    <div className={classes.root} data-hook={dataHook}>
      <Text data-hook={SubscriptionDataHook.Name} className={classes.text}>
        {subscription.name}
      </Text>
      <Text data-hook={SubscriptionDataHook.Duration} className={classes.text}>
        {duration}
      </Text>
    </div>
  );
};
