import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {LineItemModel} from '../../../../domain/models/checkout/LineItem.model';
import {LineItemOptionModel} from '../../../../domain/models/checkout/LineItemOption.model';
import {classes} from './LineItem.st.css';
import {getPaymentTypeLabel, getLineItemViolationIfItExists} from './LineItem.utils';
import {ComWixEcommerceCatalogSpiApiV1PaymentOptionType} from '../../../../gql/graphql';
import {LineItemAdditionalInfo} from './LineItemAdditionalInfo';
import {useCurrencyFormatter, useExperiments} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../Widget/ControllerContext';
import {ItemLayout} from '../../../../common/components/Item/ItemLayout';
import {ItemImage, Thumbnail} from '../../../../common/components/Item/ItemImage/ItemImage';
import {ItemName} from '../../../../common/components/Item/ItemName/ItemName';
import {ItemPrice} from '../../../../common/components/Item/ItemPrice/ItemPrice';
import {ItemOptions} from '../../../../common/components/Item/ItemOptions/ItemOptions';
import {ItemSubscription} from './ItemSubscription/ItemSubscription';
import {MAX_ITEM_OPTION_LENGTH, NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE, SPECS} from '../../../../common/constants';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {getLineItemSubscriptionFrequencyText} from '../../../../domain/utils/getLineItemSubscriptionFrequencyText';

export enum LineItemDataHooks {
  LineItemWrapper = 'LineItemDataHooks.LineItemWrapper',
  Name = 'LineItemDataHooks.Name',
  ComparePrice = 'LineItemDataHooks.ComparePrice',
  Price = 'LineItemDataHooks.Price',
  SubscriptionFrequency = 'LineItemDataHooks.SubscriptionFrequency',
  Subscription = 'LineItemDataHooks.Subscription',
  Options = 'LineItemDataHooks.Options',
  OutOfStock = 'LineItemDataHooks.OutOfStock',
  Image = 'LineItemDataHooks.Image',
  DefaultImage = 'LineItemDataHooks.DefaultImage',
}

export const LineItemRow = ({
  lineItem,
  useLayoutForLongPrice,
}: {
  lineItem: LineItemModel;
  useLayoutForLongPrice: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const {
    checkoutStore: {shouldShowViolations, checkout},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const formatCurrency = useCurrencyFormatter();

  const hidePrice =
    lineItem.renderingConfig?.hidePrice ||
    (experiments.enabled(SPECS.AddLayoutConfigToLineItemsAutoGql) && lineItem.priceUndetermined);
  const shouldUseNewSubscriptionView = experiments.enabled(SPECS.UseNewSubscriptionView);
  const shouldDisplayFrequency =
    lineItem.subscription && shouldUseNewSubscriptionView && lineItem.subscription.billingCycles !== 1;
  const thumbnail: Thumbnail | undefined = lineItem.media
    ? {
        src: lineItem.media.src,
        height: lineItem.media.height,
        width: lineItem.media.width,
        altText: lineItem.media.altText,
      }
    : undefined;

  const getQuantityAsLineItemOption = () => {
    return new LineItemOptionModel({
      value: `${lineItem.quantity}`,
      title: localeKeys.checkout.order_summary.items.quantity(),
    });
  };

  const getSKUAsLineItemOption = () => {
    return new LineItemOptionModel({
      value: lineItem.sku!,
      title: localeKeys.checkout.order_summary.items.sku(),
    });
  };

  const getPaymentTypeAsLineOption = () => {
    return new LineItemOptionModel({
      value: getPaymentTypeLabel(lineItem, localeKeys, experiments.enabled(SPECS.HideDepositWhenZero)),
      title: '',
    });
  };

  const getLineItemOptions = (): {title: string; value: string}[] => {
    const options = [];
    const hideQuantity =
      lineItem.renderingConfig?.hideQuantity ||
      (experiments.enabled(SPECS.AddLayoutConfigToLineItemsAutoGql) && lineItem.fixedQuantity);

    if (lineItem.paymentOption !== ComWixEcommerceCatalogSpiApiV1PaymentOptionType.FULL_PAYMENT_ONLINE) {
      options.push(getPaymentTypeAsLineOption());
    }
    if (!hideQuantity) {
      options.push(getQuantityAsLineItemOption());
    }
    lineItem.sku && options.push(getSKUAsLineItemOption());
    options.push(...lineItem.options);
    return options;
  };

  const getSalePriceAriaLabel = () => {
    return lineItem.prices.comparePrice
      ? localeKeys.checkout.orderSummary.salePrice.ariaLabel({
          price: lineItem.prices.lineItemPrice.formattedAmount,
        })
      : localeKeys.checkout.orderSummary.price.ariaLabel({
          price: lineItem.prices.lineItemPrice.formattedAmount,
        });
  };

  const isItemOutOfStock = lineItem.quantity === 0;
  const lineItemViolation = shouldShowViolations
    ? getLineItemViolationIfItExists(checkout.violations, lineItem.id)
    : undefined;

  const getComparePriceValueText = () => {
    if (!lineItem.prices.comparePrice?.amount) {
      return undefined;
    }
    const totalPriceByQuantity = lineItem.prices.comparePrice?.amount * lineItem.quantity;
    return formatCurrency({value: totalPriceByQuantity, currency: checkout.currency!});
  };

  return (
    <li
      data-hook={LineItemDataHooks.LineItemWrapper}
      className={`${isItemOutOfStock || lineItemViolation ? classes.additionalInfoExist : ''}`}>
      <ItemLayout
        descriptionWithColumns={!useLayoutForLongPrice}
        Icon={
          <ItemImage
            thumbnail={thumbnail}
            imageDataHook={LineItemDataHooks.Image}
            defaultImageDataHook={LineItemDataHooks.DefaultImage}
          />
        }
        MainDescription={<ItemName name={lineItem.productName} dataHook={LineItemDataHooks.Name} />}
        SecondaryDescription={
          // eslint-disable-next-line no-nested-ternary
          hidePrice ? null : shouldUseNewSubscriptionView ? (
            <div className={classes.priceContainer}>
              <ItemPrice
                comparePriceDataHook={LineItemDataHooks.ComparePrice}
                priceDataHook={LineItemDataHooks.Price}
                shortPrice={!useLayoutForLongPrice}
                formattedPrice={lineItem.prices.lineItemPrice.formattedAmount}
                priceAriaLabel={getSalePriceAriaLabel()}
                formattedComparePrice={getComparePriceValueText()}
                comparePriceAriaLabel={
                  lineItem.prices.comparePrice
                    ? localeKeys.checkout.orderSummary.regularPrice.ariaLabel({
                        price: lineItem.prices.comparePrice?.formattedAmount,
                      })
                    : undefined
                }
              />
              {shouldDisplayFrequency && (
                <Text
                  className={classes.subscriptionFrequencyText}
                  priority={TextPriority.secondary}
                  data-hook={LineItemDataHooks.SubscriptionFrequency}>
                  {getLineItemSubscriptionFrequencyText(lineItem.subscription!, localeKeys)}
                </Text>
              )}
            </div>
          ) : (
            <ItemPrice
              comparePriceDataHook={LineItemDataHooks.ComparePrice}
              priceDataHook={LineItemDataHooks.Price}
              shortPrice={!useLayoutForLongPrice}
              formattedPrice={lineItem.prices.lineItemPrice.formattedAmount}
              priceAriaLabel={getSalePriceAriaLabel()}
              formattedComparePrice={getComparePriceValueText()}
              comparePriceAriaLabel={
                lineItem.prices.comparePrice
                  ? /* istanbul ignore next */ localeKeys.checkout.orderSummary.regularPrice.ariaLabel({
                      price: lineItem.prices.comparePrice?.formattedAmount,
                    })
                  : undefined
              }
            />
          )
        }
        Details={
          <div className={classes.itemData}>
            {lineItem.subscription && (
              <ItemSubscription subscription={lineItem.subscription} dataHook={LineItemDataHooks.Subscription} />
            )}
            <ItemOptions
              dataHook={LineItemDataHooks.Options}
              itemId={lineItem.id}
              options={getLineItemOptions()}
              maxOptionValLength={MAX_ITEM_OPTION_LENGTH}
              collapseSettings={{
                numberOfOptionsToCollapseAfter: NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE + 1,
                showMoreTitle: localeKeys.checkout.order_summary.items.more_details(),
                showLessTitle: localeKeys.checkout.order_summary.items.lessDetails(),
              }}
            />
          </div>
        }
        AdditionalInfo={
          (isItemOutOfStock || lineItemViolation) && (
            <LineItemAdditionalInfo
              lineItem={lineItem}
              isItemOutOfStock={isItemOutOfStock}
              lineItemViolation={lineItemViolation}
            />
          )
        }
      />
    </li>
  );
};
