import React from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './Footer.st.css';
import {InnerFooter} from '../../../common/components/InnerFooter/InnerFooter';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../common/constants';

export const Footer = ({dataHook}: {dataHook: string}) => {
  const {
    checkoutSettingsStore: {
      checkoutSettings: {termsAndConditions, privacyPolicy, returnPolicy, contactUs, customPolicy, digitalPolicy},
    },
    checkoutStore: {checkout, onPolicyClicked},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const shouldShowItemPolicies = experiments.enabled(SPECS.AddPoliciesToLineItems);
  const shouldCheckLineItemPolicies = shouldShowItemPolicies
    ? Object.keys(checkout.lineItemPolicies || /* istanbul ignore next */ {}).length <= 0
    : true;

  if (
    !termsAndConditions.enabled &&
    !privacyPolicy.enabled &&
    !returnPolicy.enabled &&
    !contactUs.enabled &&
    !customPolicy.enabled &&
    !(digitalPolicy.enabled && checkout.hasDigitalItems) &&
    checkout.paymentPolicies.length === 0 &&
    shouldCheckLineItemPolicies
  ) {
    return null;
  }

  return (
    <footer className={classes.footer} data-hook={dataHook}>
      <InnerFooter
        termsAndConditions={termsAndConditions}
        privacyPolicy={privacyPolicy}
        returnPolicy={returnPolicy}
        digitalPolicy={digitalPolicy}
        contactUs={contactUs}
        customPolicy={customPolicy}
        shouldShowDigitalItemPolicy={checkout.hasDigitalItems}
        paymentPolicies={checkout.paymentPolicies}
        lineItemPolicies={shouldShowItemPolicies ? checkout.lineItemPolicies : undefined}
        onPolicyClicked={onPolicyClicked}
      />
    </footer>
  );
};
