import React, {ComponentType, FC, useMemo} from 'react';
import {withDeliverySection} from '@wix/delivery-section-checkout/controller';
import {CheckoutControllerProps} from '../types/checkoutApp.types';
import {WidgetProps} from '@wix/yoshi-flow-editor';

export const withConditionalDeliverySection = (
  Component: ComponentType<WidgetProps<CheckoutControllerProps>>
): FC<WidgetProps<CheckoutControllerProps>> => {
  const Wrapper: FC<WidgetProps<CheckoutControllerProps>> = (props) => {
    const WrappedComponent = useMemo(() => {
      return props?.stepsManagerStore?.shouldUseDeliverySectionFromLibrary ? withDeliverySection(Component) : Component;
    }, [props?.stepsManagerStore?.shouldUseDeliverySectionFromLibrary]);

    return <WrappedComponent {...props} />;
  };

  Wrapper.displayName = `withDeliverySectionProvider(${
    Component.displayName || Component.name || /*istanbul ignore next*/ 'Component'
  })`;

  return Wrapper;
};
