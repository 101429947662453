import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {classes} from './SubscriptionsProjections.st.css';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../common/constants';

export enum SubscriptionsProjectionsDataHooks {
  root = 'SubscriptionsProjectionsDataHooks.root',
  projectionText = 'SubscriptionsProjectionsDataHooks.projectionText',
}

export const SubscriptionsProjections = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  const {experiments} = useExperiments();

  const projections = checkout?.subscriptionsProjections;
  const shouldRenderProjections =
    projections && projections.length > 0 && experiments.enabled(SPECS.UseNewSubscriptionView);

  return shouldRenderProjections ? (
    <div data-hook={SubscriptionsProjectionsDataHooks.root} className={classes.root}>
      {projections.map((projection, index) => (
        <div key={index}>
          <Text
            priority={TextPriority.secondary}
            className={classes.projectionText}
            data-hook={SubscriptionsProjectionsDataHooks.projectionText}>
            {projection}
          </Text>
        </div>
      ))}
    </div>
  ) : null;
};
